import React from 'react'
import ThankYouHeader from '../../src/components/Sections/ThankyouPage/ThankYouHeader'
import TopNavbar from '../components/Nav/TopNavbar'

function ThankYou() {
  return (
    <div>
        <TopNavbar/>
        <ThankYouHeader/>
       
    </div>
  )
}

export default ThankYou